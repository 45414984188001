(function (angular) {
    angular.module('MyHippoProducer.Controllers').controller('OrganizationListController', OrganizationListController);
    OrganizationListController.$inject = ['$log', '$scope', '$state', '$timeout', 'APIService', 'UserService'];
    function OrganizationListController ($log, $scope, $state, $timeout, APIService, UserService) {
        $log.info('OrganizationListController');

        $scope.canEditOrganizations = UserService.canEditOrganizations();

        $scope.findOrganizations = function () {
            APIService.findAvailableOrganizationsForUser().then(function (results = []) {
                $scope.organizations = results;
                $scope.totalItems = results.length;
            });
        };
        $scope.findOrganizations();

        $scope.openOrganizationDetails = function (organizationId) {
            $state.go('portal.organizationDetails', {id: organizationId});
        };
    }

    angular.module('MyHippoProducer.Controllers').controller('OrganizationDetailsController', OrganizationDetailsController);
    function OrganizationDetailsController ($log, $scope, $state, $stateParams, spinnerService, toaster, WorkflowUtil, APIService, organizationData, UserService) {
        $log.info('OrganizationDetailsController');
        var vm = this;
        vm.orgId = $stateParams.id;
        $scope.canEditOrganizations = UserService.canEditOrganizations();
        $scope.workflow = organizationData.workflow;
        $scope.workflowOriginalMode = WorkflowUtil.getWorkflowObjectInfo({});
        $scope.workflowModel = {};

        var workflowCtrl;
        $scope.setWorkflowCtrl = function (wfCtrl) {
            workflowCtrl = wfCtrl;
        };

        $scope.updateData = function (organizationInfo) {
            vm.organizationInfo = organizationInfo;

            // required for workflow controllers
            $scope.workflowOriginalModel = organizationInfo;
            $scope.workflowModel = WorkflowUtil.extendWorkflowModel($scope.workflowModel, _.cloneDeep(organizationInfo));
            $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on workflowModel
        };
        $scope.updateData(organizationData.organizationInfo);

        vm.save = function () {
            if (!workflowCtrl.getPageCtrl().validate()) {
                toaster.pop('error', 'Producer Info', 'Invalid form');
                return;
            }

            spinnerService.show('globalSpinner');

            var deltaUpdates = {};
            return workflowCtrl.getPageCtrl().getDeltaUpdate(deltaUpdates).then(function () {
                return APIService.updateOrganization(vm.orgId, deltaUpdates);
            }).then(function (organizationInfo) {
                $scope.updateData(organizationInfo);
                $scope.workflowErrors = [];

                $scope.$broadcast('policyupdate.success');
                toaster.pop('success', 'Organization Info', 'Successfully saved!');
            }, function (errors) {
                $scope.workflowErrors = errors;
                $scope.workflowModel['__trigger_digest'] = Date.now(); // workaround: trigger a digest on the form

                toaster.pop('error', 'Organization', 'An error occurred while saving the Organization<br>' + (typeof (errors) === 'string' ? errors : ''), 3000, 'trustedHtml');
                $scope.$broadcast('policyupdate.error');
                throw errors;
            }).finally(function () {
                spinnerService.hide('globalSpinner');
            });
        };

        vm.cancel = function () {
            $state.go('portal.policiesList');
        };
    }
})(window.angular);
